import { Action } from '@ngrx/store';
import { UserInfo, Language } from 'app/shared/models';

export enum Type {
  LOAD_USER_INFO = '[USER_INFO] LOAD_USER_INFO',
  LOAD_USER_INFO_SUCCESS = '[USER_INFO] LOAD_USER_INFO_SUCCESS',
  SET_USER_LANGUAGE = '[USER_INFO] SET_USER_LANGUAGE',
  SET_USER_LANGUAGE_FROM_TOKEN = '[USER_INFO] SET_USER_LANGUAGE_FROM_TOKEN',
  SET_USER_LANGUAGE_SUCCESS = '[USER_INFO] SET_USER_LANGUAGE_SUCCESS',
  SET_USER_CONTEXT_LANGUAGE = '[USER_INFO] SET_USER_CONTEXT_LANGUAGE',
  SET_USER_CONTEXT_LANGUAGE_SUCCESS = '[USER_INFO] SET_USER_CONTEXT_LANGUAGE_SUCCESS',
  LOAD_CONTEXT_FROM_TOKEN = '[USER_INFO] LOAD_CONTEXT_FROM_TOKEN',
  SWITCH_USER_CONTEXT = '[USER_INFO] SWITCH_USER_CONTEXT'
}

export class LoadUserInfo implements Action {
  readonly type = Type.LOAD_USER_INFO;
  constructor(public payload?: number) { }
}

export class LoadUserInfoSuccess implements Action {
  readonly type = Type.LOAD_USER_INFO_SUCCESS;
  constructor(public payload: UserInfo) { }
}

export class SetUserLanguage implements Action {
  readonly type = Type.SET_USER_LANGUAGE;
  constructor(public payload: Language) { }
}

export class SetUserLanguageFromToken implements Action {
  readonly type = Type.SET_USER_LANGUAGE_FROM_TOKEN;
  constructor(public payload: { language: Language, token: string }) { }
}
export class SetUserLanguageSuccess implements Action {
  readonly type = Type.SET_USER_LANGUAGE_SUCCESS;
  constructor(public payload: Language) { }
}

export class SetUserContextLanguage implements Action {
  readonly type = Type.SET_USER_CONTEXT_LANGUAGE;
  constructor(public payload: Language) { }
}
export class SetUserContextLanguageSuccess implements Action {
  readonly type = Type.SET_USER_CONTEXT_LANGUAGE_SUCCESS;
  constructor(public payload: Language) { }
}

export class LoadContextFromToken implements Action {
  readonly type = Type.LOAD_CONTEXT_FROM_TOKEN;
  constructor(public token: string) { }
}

export class SwitchUserContext implements Action {
  readonly type = Type.SWITCH_USER_CONTEXT;
  constructor(public payload: number, public onSuccess: Action[] = []) { }
}

export type Actions = LoadUserInfo | LoadUserInfoSuccess
  | SetUserLanguage | SetUserLanguageSuccess
  | SetUserContextLanguage | SetUserContextLanguageSuccess
  | LoadContextFromToken | SetUserLanguageFromToken
  | SwitchUserContext;
